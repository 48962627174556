import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import PosterPage from "../components/Sections/PosterPage";
import Footer from "../components/Sections/Footer";

export default function Poster() {
  return (
    <div style={{position: "relative", minHeight: '100vh'}}>
      <div style={{paddingBottom: '5rem'}}>
        <TopNavbar/>
        <PosterPage />
      </div>
      <Footer />
    </div>
  );
}


