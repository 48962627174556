import * as React from "react";
import Key from "./key.svg"

function SvgComponent(props) {
  return (
    <img
      src={Key}
      alt="An icon of a key to show how secure Code Poster is"
      {...props}
    />
  );
}

export default SvgComponent;
