import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import BulbIcon from "../../assets/svg/Services/BulbIcon";
import KeyIcon from "../../assets/svg/Services/KeyIcon";
import StarIcon from "../../assets/svg/Services/StarIcon";

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <RollerIcon style={{width: 100, height: 100}}/>;
      break;
    case "bulb":
      getIcon = <BulbIcon style={{width: 100, height: 100}} />;
      break;
    case "key":
      getIcon = <KeyIcon style={{width: 100, height: 100}} />;
      break;
    case "star":
      getIcon = <StarIcon style={{width: 100, height: 100}} />;
      break;
    default:
      getIcon = <RollerIcon style={{width: 100, height: 100}} />;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  text-align: center;
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 10px 0;
  text-align: center;
  @media (max-width: 860px) {
    padding: 5px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;