import * as React from "react";
import Bulb from "./bulb.svg"

function SvgComponent(props) {
  return (
    <img
      src={Bulb}
      alt="An icon of a lightbulb to show how unique the posters generate by Code Poster are"
      {...props}
    />
  );
}

export default SvgComponent;
