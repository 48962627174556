import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import Poster from "./screens/Poster.jsx";
import Purchase from "./screens/Purchase.jsx";
import PurchaseCancelled from "./screens/PurchaseCancelled.jsx";
import GenericLegalScreen from "./screens/legal/GenericLegalScreen.jsx";
import Disclaimer from "./screens/legal/Disclaimer";
import EULA from "./screens/legal/EULA";
import PrivacyPolicy from "./screens/legal/PrivacyPolicy";
import TOS from "./screens/legal/TermsOfService";
import PageNotFound from "./screens/PageNotFound";

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <Router>
      <Routes>
          <Route path="/purchaseCancelled" element={<PurchaseCancelled />}></Route>
          <Route path="/purchaseComplete" element={<Purchase />}></Route>
          <Route path="/poster" element={<Poster />}></Route>
          <Route path="/disclaimer" element={<GenericLegalScreen component={<Disclaimer />} />}></Route>
          <Route path="/privacy" element={<GenericLegalScreen component={<PrivacyPolicy />} />}></Route>
          <Route path="/tos" element={<GenericLegalScreen component={<TOS />} />}></Route>
          <Route path="/eula" element={<GenericLegalScreen component={<EULA />} />}></Route>
          <Route path="/" element={<Landing />}></Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}

