/* eslint-disable */ 
import React from "react";
import styled from "styled-components";
// Assets
import PosterExample from "../../assets/img/header-img.png";{}

export default function PurchaseCancelledPage() {
  
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide>
        <h1 className="extraBold font60">Purchase Cancelled</h1>
        <HeaderP className="font13 semiBold">
          Your payment has been cancelled and you have not been charged
        </HeaderP>
        <HeaderP className="font13 semiBold">
        <a style={{color: "#fba52d", cursor: "pointer"}} onClick={() => window.location.href="/"}>Return To Code Poster</a>
        </HeaderP>
      </LeftSide>
      <RightSide>
        <ImageWrapper style={{justifyContent: "flex-end"}}>
          <Img
            className="radius8"
            src={PosterExample}
            alt="Poster"
            style={{backgroundColor: "white",
              boxShadow: "0 1px 3px rgba(34, 25, 25, 0.4)",
              MozBoxShadow: "0 1px 2px rgba(34,25,25,0.4)",
              WebkitBoxShadow: "0 1px 3px rgba(34, 25, 25, 0.4)"}} />
        </ImageWrapper>
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 80%;
    order: 1;
    margin: 50px 20px;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 20px 50px 20px;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin-top: 30px;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center !important;
  }
`;
const Img = styled.img`
  width: 80%;
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 0px 0;
  line-height: 1.5rem;
  font-size: 20px !important;
  @media (max-width: 960px) {
    padding: 5px 0 0px 0;
    text-align: center;
    max-width: 100%;
  }
`;