import NoDataPoster from "../../assets/img/ErrorNoCode.png";


/**
 * Trigger the canvas to be drawn based on the config values provided
 * Returns the canvas (if applicable)
 */
 export const drawCanvas = async (
    canvasId,
    imgSrc,
    text,
    canvasWidth,
    canvasHeight,
    backgroundColour,
    fontSize,
    font,
    textColour,
    lineSpacing,
    imgScaling,
    setLogoError,
    setLoadedPoster,
    setCodeError,
    setLoadingPoster,
    addWater) => {
    if (imgSrc === undefined){
        setLogoError(true);
        setLoadedPoster(NoDataPoster);
        return;
    }
    setLogoError(false);
    if (text === undefined || text === null || text.replace(/\s/g, "").length === 0){
        setCodeError(true);
        setLoadedPoster(NoDataPoster);
        return;
    }
    setCodeError(false);
    setLoadedPoster(undefined);

    setLoadingPoster(true);
    setCodeError(false);
    var reader = new FileReader();
    reader.onload = function(e) {
        // Create a new image from the imported data
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
            var canvas = document.getElementById(canvasId);

            // Create the canvas
            var width = isNaN(canvasWidth) || canvasWidth === "0" ? img.width : parseFloat(canvasWidth);
            var height = isNaN(canvasHeight) || canvasHeight === "0" ? img.height : parseFloat(canvasHeight);

            canvas.width = width;
            canvas.height = height;
            var ctx = canvas.getContext('2d');

            // Set background
            ctx.fillStyle=backgroundColour;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Get the font record. If the font doesn't exist, quit
            const fontRecord = AcceptableFonts.filter(x => x.name === font);
            if (fontRecord.length === 0){
                setCodeError(true);
                setLoadingPoster(false);
                return canvas;
            }

            ctx.font = "normal " + fontSize + "px " + font;
            ctx.globalCompositeOperation = "destination-out";

            // Write text
            const justifiedText = justifyText(ctx, text.replace(/(\r\n|\n|\r)/gm,"").replace(/ +(?= )/g,''), width);
            const textHeight = getFontPxSizeForFontSize(fontRecord[0].heightOfFontForTenPixels || 6, fontSize);

            for (const [lineNumber, lineText] of justifiedText.entries()){
                ctx.fillText(lineText, 0, textHeight + (lineNumber * ((lineSpacing*0.4) + textHeight)));
            }

            ctx.globalCompositeOperation = "destination-over";
            const imgWidth = canvas.width * parseFloat(imgScaling/100);
            const imgHeight = (canvas.width / img.width) * img.height * parseFloat(imgScaling/100);

            // Center the logo
            const positionY = (ctx.canvas.height / 2) - (imgHeight / 2);
            const positionX = (ctx.canvas.width / 2) - (imgWidth / 2);

            ctx.drawImage(img, positionX, positionY, imgWidth, imgHeight);

            // Set the text colour that's not covered by logo
            ctx.fillStyle=textColour;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Add the watermark
            if (addWater){
                ctx.globalCompositeOperation = "source-over";
                ctx.globalAlpha=.25;
    
                text = "Code Poster"
                let currentFontSize = 10;
                while (true){
                    ctx.font = currentFontSize + "px verdana";
                    if (ctx.measureText(text).width > canvas.width)
                    {
                        ctx.font = currentFontSize-20 + "px verdana";
                        break
                    }
    
                    currentFontSize += 20;
                }
    
                ctx.textAlign = "center";
                ctx.fillStyle='black';
                ctx.fillText(text, canvas.width / 2, canvas.height / 2);
                ctx.fillStyle='white';
                ctx.fillText(text, (canvas.width / 2) + 10, (canvas.height / 2) + 10);
            }

            setLoadingPoster(false);
        };
    };
    reader.readAsDataURL(imgSrc);
};

/**
 * Calculates the pixel size of a font based on the px font size.
 * @param {number} charWidthSizeThen: The width (or height) of the font when it is size 10.
 * @param {number} fontSize: The font size.
 */
 const getFontPxSizeForFontSize = (charWidthSizeTen, fontSize) => (charWidthSizeTen / 10) * fontSize;
 /**
  * Justifies the text.
  * @param {canvasContext} ctx: The canvas context to use.
  * @param {string} text: The text to justify.
  * @param {number} maxWidth: The max width to justify the text to.
  */
 const justifyText = (ctx, text, maxWidth) => {
     // Calculate the number of characters needed for the first line
     let s = "1";
     while(true){
         const width = ctx.measureText(s).width;
 
         if (width > maxWidth){
             break
         }
         s += "1";
     }
 
     // As we're using monospaced fonts, we can safely split the string by this length each time
     const arr = [];
     const maxLineLength = s.length;
     for (let index = 0; index < text.length; index += maxLineLength) {
         arr.push(text.slice(index, index + maxLineLength));
       }
     return arr;
 }

 const AcceptableFonts = [
    {
        name: "AnonymousPro",
        path: "fonts/AnonymousPro.ttf",
        heightOfFontForTenPixels: 6,
    },
    {
        name: "BPMono",
        path: "fonts/BPMono.ttf",
        heightOfFontForTenPixels: 6,
    },
    {
        name: "Cella",
        path: "fonts/Cella.ttf",
        heightOfFontForTenPixels: 9,
    },
    {
        name: "Monospace",
        path: "fonts/Monospace.ttf",
        heightOfFontForTenPixels: 7,
    },
    {
        name: "SansMonoRoman",
        path: "fonts/SansMonoRoman.ttf",
        heightOfFontForTenPixels: 8,
    },
];