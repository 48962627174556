import React, {useState} from 'react'
import reactCSS from 'reactcss'
import { ChromePicker } from 'react-color'

export const ColourPicker = ({colour, setColour}) => {
  const [showColourPicker, setShowColourPicker] = useState(false);

  const handleClick = () => {
    setShowColourPicker(x => !x);
  };

  const handleClose = () => {
    setShowColourPicker(false)
  };

  const handleChange = (color) => {
    setColour(color.hex)
  };

    const styles = reactCSS({
      'default': {
        color: {
          height: '14px',
          borderRadius: '2px',
          background: colour,
          width: "100%"
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          width: "100%"
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
          marginTop: "-278px",
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <div style={ styles.swatch } onClick={ handleClick }>
          <div style={ styles.color } />
        </div>
        { showColourPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ handleClose }/>
          <ChromePicker color={ colour } onChange={ handleChange } />
        </div> : null }

      </div>
    );
}

export default ColourPicker