import * as React from "react";
import Star from "./star.svg"

function SvgComponent(props) {
  return (
    <img
      src={Star}
      alt="An icon of a star to show how fast and flexible Code Poster is"
      {...props}
    />
  );
}

export default SvgComponent;
