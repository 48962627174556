/* eslint-disable */ 
import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {CircularProgress} from '@mui/material';
// Components
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
// Assets
import PosterExample from "../../assets/img/header-img.png";
import ErrorCouldNotDownload from "../../assets/img/ErrorCouldNotDownload.png";
import {SERVER_ROOT} from "../../Constants";

const POSTER_NAME = "poster.png"

function download(dataurl, filename) {
  const link = document.createElement("a");
  link.href = dataurl;
  link.download = filename;
  link.click();
}

export default function PurchasePage() {
  const [loadedPoster, setLoadedPoster] = useState();
  const [loadingPoster, setLoadingPoster] = useState(false);
  const [posterError, setPosterError] = useState(false);

  useEffect(() => {
    setLoadingPoster(true);
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams?.get("token");
    if (token === undefined || token === null){
      setPosterError(true);
      setLoadingPoster(false);
      return;
    }

    fetch(`${SERVER_ROOT}claimPurchase?token=${token}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin" : '*'
      }
    }).then(x => {
      console.log(x.status);
      if (x.status === 200){
        x.text()
        .then(y => {
          setLoadedPoster(y);
          download(y, POSTER_NAME);
        })
      }
      else{
        setPosterError(true);
      }
    }).catch(x => {
      console.error(x);
      setPosterError(true);
    })
    .finally(() => setLoadingPoster(false))
  }, []);

  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide>
        <h1 className="extraBold font60">Purchase Complete!</h1>
        <HeaderP className="font13 semiBold">
          Thank you for creating a poster with Code Poster
        </HeaderP>
        <HeaderP className="font13 semiBold">
          Your download should begin automatically.
        </HeaderP>
        <HeaderP className="font13 semiBold">
        If it doesn't, <a style={{color: loadedPoster ? "#fba52d" : undefined, cursor: "pointer"}} href={null} onClick={() => download(loadedPoster, POSTER_NAME)}>click here</a> to download it manually
        </HeaderP>
        <div style={{marginTop: 40, display: "flex", flexDirection: "row", gap: 10}} >
          <WarningAmberIcon style={{color: "#fba52d", fontSize: 40, paddingTop: 15}} />
          <HeaderP className="font13 semiBold">
            Remember to keep your poster safe, as this link will expire after 24 hours
          </HeaderP>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper style={{justifyContent: loadingPoster ? "center" : "flex-end"}}>
          {loadingPoster ?
          (<CircularProgress style={{color: "#fba52d"}}/>) :
          (<Img
            className="radius8"
            src={loadedPoster || (posterError ? ErrorCouldNotDownload : PosterExample)}
            alt="Poster"
            style={{backgroundColor: "white",
              boxShadow: "0 1px 3px rgba(34, 25, 25, 0.4)",
              MozBoxShadow: "0 1px 2px rgba(34,25,25,0.4)",
              WebkitBoxShadow: "0 1px 3px rgba(34, 25, 25, 0.4)"}} />)}
        </ImageWrapper>
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 80%;
    order: 1;
    margin: 50px 20px;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 20px 50px 20px;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin-top: 30px;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center !important;
  }
`;
const Img = styled.img`
  width: 80%;
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 0px 0;
  line-height: 1.5rem;
  font-size: 20px !important;
  @media (max-width: 960px) {
    padding: 5px 0 0px 0;
    text-align: center;
    max-width: 100%;
  }
`;