import React from "react";
import styled from "styled-components";
import {CircularProgress} from '@mui/material';

export default function FullButton({ title, action, border, loading }) {
  return (
    <Wrapper
      className="pointer radius8"
      onClick={!loading && action ? () => action() : null}
      border={border}
      style={{padding: loading ? "9px" : "15px"}}
    >
      {loading ? <CircularProgress style={{color: "#fff", margin: 0}} size="25px"/> : title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#F2B300")};
  background-color: ${(props) => (props.border ? "transparent" : "#F2B300")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#F2B300")};
    border: 1px solid #F2B300;
    color: ${(props) => (props.border ? "#F2B300" : "#fff")};
  }
`;

