import React, {useState} from "react";
import styled from "styled-components";
import {CircularProgress} from '@mui/material';
// Components
import Editor from "../PosterEditor/Editor";
// Assets
import PosterExample from "../../assets/img/header-img.png";

export default function PosterPage() {
  const [loadedPoster, setLoadedPoster] = useState(PosterExample);
  const [loadingPoster, setLoadingPoster] = useState(false);

  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <Editor
          loadedPoster={loadedPoster}
          setLoadedPoster={setLoadedPoster}
          setLoadingPoster={setLoadingPoster}
          loadingPoster={loadingPoster}/>
      </LeftSide>
      <RightSide>
        <ImageWrapper style={{justifyContent: loadingPoster ? "center" : "flex-end"}}>
          {loadingPoster ?
          (<CircularProgress style={{color: "#fba52d"}}/>) :
          loadedPoster ? (<Img
            className="radius8"
            src={loadedPoster || PosterExample}
            alt="Poster"
            style={{backgroundColor: "white",
            boxShadow: "0 1px 3px rgba(34, 25, 25, 0.4)",
            MozBoxShadow: "0 1px 2px rgba(34,25,25,0.4)",
            WebkitBoxShadow: "0 1px 3px rgba(34, 25, 25, 0.4)"}} />)
             : (<></>)}
          <canvas
            id="canvas"
            style={{
              display: loadingPoster || loadedPoster ? "none" : undefined,
              backgroundColor: "white",
              boxShadow: "0 1px 3px rgba(34, 25, 25, 0.4)",
              MozBoxShadow: "0 1px 2px rgba(34,25,25,0.4)",
              WebkitBoxShadow: "0 1px 3px rgba(34, 25, 25, 0.4)",
              width: "90%"}} />
              <canvas
            id="canvasBackup"
            style={{
              display: "none",
              backgroundColor: "white",
              boxShadow: "0 1px 3px rgba(34, 25, 25, 0.4)",
              MozBoxShadow: "0 1px 2px rgba(34,25,25,0.4)",
              WebkitBoxShadow: "0 1px 3px rgba(34, 25, 25, 0.4)",
              width: "90%"}} />
        </ImageWrapper>
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 80%;
    order: 2;
    margin: 50px 20px;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 20px 50px 20px;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center !important;
  }
`;
const Img = styled.img`
  width: 80%;
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;


