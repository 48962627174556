import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";
import styled from "styled-components";

export default function PageNotFount() {
  return (
    <div style={{position: "relative", minHeight: '100vh'}}>
      <div style={{paddingBottom: '5rem'}}>
        <TopNavbar/>
        <Wrapper id="home" className="container flexSpaceCenter">
          <div>
          <h1 className="extraBold font60" style={{fontSize: 30, paddingBottom: 0, lineHeight: 2}}>Sorry, the page you were looking for could not be found</h1>
          <h1
            className="extraBold font60"
            style={{fontSize: 20, paddingBottom: 0, lineHeight: 2, textAlign: "center", marginTop: 0, cursor: "pointer", color: "#f4a424"}}
            onClick={() => window.location.href="/"}>Return Home</h1>
          </div>
        </Wrapper>
      </div>
      <Footer />
    </div>
  );
}

const Wrapper = styled.section`
  text-align: left;
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  flex-direction: column;
  @media (max-width: 960px) {
    flex-direction: column;
  }

  h1,h2{
    margin-top: 40px;
  }
`;

