import React from "react";
import styled from "styled-components";
// Components
import ServiceBox from "../Elements/ServiceBox";

export default function Features() {
  return (
    <Wrapper id="features">
      <div className="lightBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Features</h1>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="Customisable"
                subtitle="Your poster, your design. Add your logo and code, choose your canvas size, and so much more"
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="key"
                title="Secure"
                subtitle="Keep your project files secure. Files submitted to this site are transferred securely and are only used to create your poster"
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
              icon="star"
              title="Fast and Flexible"
              subtitle="Download your poster in seconds. Use it on a poster, as a desktop background, or put it on a mug. The choice is yours" />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="bulb"
                title="Unique"
                subtitle="Make a poster as unique as your project is, with a custom logo and code"
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  margin: 0;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 20px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }
`;
const HeaderInfo = styled.div`
  margin: 0;
  text-align: center;
  @media (max-width: 860px) {
    text-align: center;
  }
`;