import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import {isMobile} from "react-device-detect";

export default function Footer({showBackToTop}) {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg" style={{height: "auto"}}>
        <div className="container" style={{height: "auto"}}>
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "10px 0", display: "flex", flexDirection: "column" }}>
            <div style={{display: "flex", flexDirection: isMobile ? "column" : "row"}}>
              <StyleP className="whiteColor font13">
                © {getCurrentYear()} - <span className="purpleColor font13">Code Poster</span> All Rights Reserved
              </StyleP>
              
              {!isMobile && <Link className="whiteColor animate pointer font13" to="home" smooth={true} style={{position: "absolute", right: 40}}>
                {showBackToTop && "Back to top"}
              </Link>
              }

            </div>
            <div style={{display: "flex", flexDirection: "row", gap: 20}}>
              <StyleP className="whiteColor font13" style={{cursor: "pointer"}} onClick={() => window.location.href="/disclaimer"}>
                Disclaimer
              </StyleP>
              <StyleP className="whiteColor font13" style={{cursor: "pointer"}} onClick={() => window.location.href="/eula"}>
                EULA
              </StyleP>
              <StyleP className="whiteColor font13" style={{cursor: "pointer"}} onClick={() => window.location.href="/tos"}>
                Terms of Service
              </StyleP>
              <StyleP className="whiteColor font13" style={{cursor: "pointer"}} onClick={() => window.location.href="/privacy"}>
                Privacy Policy
              </StyleP>
            </div>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: '1rem';
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
