import React, { useState } from "react";
import styled from "styled-components";
import {Divider, TextField, FormControlLabel, InputAdornment, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
// Components
import FullButton from "../Buttons/FullButton";
import FileUpload from "./FileUpload"
import ColourPicker from "./ColourPicker";
import CanvasSize from "./CanvasSize";
import ProcessFormData from "./ProcessFormData";
import PurchaseModal from "../Modal/PurchaseModal";
import {SERVER_ROOT} from "../../Constants";
import { drawCanvas } from "./LoadPreview";

export default function Editor({setLoadedPoster, setLoadingPoster, loadingPoster, loadedPoster}) {
  const [logo, setLogo] = useState();
  const [logoError, setLogoError] = useState(false);
  const [codeText, setCodeText] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(3508);
  const [canvasHeight, setCanvasHeight] = useState(4961);
  const [backgroundColour, setBackgroundColour] = useState("#F3F3F3");
  const [fontSize, setFontSize] = useState(50);
  const [lineSpacing, setLineSpacing] = useState(0);
  const [imageScaling, setImageScaling] = useState(100);
  const [textColour, setTextColour] = useState("#D8D8D8");
  const [font, setFont] = useState("Monospace");
  const [openPurchaseModal, setOpenPurchaseModal] = useState(false);

  return (
    <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
      <PurchaseModal
        open={openPurchaseModal}
        handleClose={() => setOpenPurchaseModal(false)}
        loadingPoster={loadingPoster}
        loadedPoster={loadedPoster}
        handlePayment={() => {
          let data = ProcessFormData(logo, codeText, canvasWidth, canvasHeight, backgroundColour, fontSize, lineSpacing, imageScaling, textColour, font, setLogoError, setLoadedPoster, setCodeError, setLoadingPoster);

          if (data === undefined){
            setLoadingPoster(false);
            return;
          }

          function dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);
            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ia], {type:mimeString});
            }

          // Get the poster from the canvas
          let poster = document.getElementById("canvasBackup").toDataURL("image/png");
          data = new FormData();
          data.append("posterData", dataURItoBlob(poster));

          fetch(SERVER_ROOT + "purchase", {
            method: "POST",
            body: data,
            headers: {
              "Access-Control-Allow-Origin" : '*'
            }
          }).then(x => 
            x.text().then(y => window.location.href = y)
          ).catch(x => {
            console.error(x);
          })
          .finally(() => setLoadingPoster(false))
        }}/>
      <h1 className="extraBold font60" style={{fontSize: 20, paddingBottom: 0, lineHeight: 2}}>Upload Files</h1>
      <div style={{display: "flex", flexDirection: "row", marginTop: 0, gap: 10 }}>
        <FileUpload title="Logo" setFile={(file) => {setLogo(file); setLogoError(false)}} dragAndDropText="" multiple={false} accept={{ 'image/jpeg': [], 'image/jpg': [], 'image/png': [] }} showFileUpload={false} error={logoError}/>
        <FileUpload title="Code" setFile={() => {setCodeError(false)}} dragAndDropText="" multiple={true} accept={{}} showFileUpload={true} codeText={codeText} setCodeText={setCodeText} error={codeError}/>
      </div>
      <Divider style={{marginTop: "30px", width: "100%"}} />

      <h1 className="extraBold font60" style={{fontSize: 20, paddingBottom: 0, lineHeight: 3}}>Canvas</h1>
      <CanvasSize canvasWidth={canvasWidth} setCanvasWidth={setCanvasWidth} canvasHeight={canvasHeight} setCanvasHeight={setCanvasHeight}/>
      <div style={{marginTop: 20}}>
        <FormControlLabel control={<></>} label="Background Colour" style={{marginLeft: 5}} />
        <ColourPicker colour={backgroundColour} setColour={setBackgroundColour} />
      </div>
      <Divider style={{marginTop: "5px", width: "100%"}} />

      <h1 className="extraBold font60" style={{fontSize: 20, paddingBottom: 0, lineHeight: 3}}>Text</h1>
      <FormControl fullWidth>
        <InputLabel id="selectFontLabel">Font</InputLabel>
        <Select
          labelId="selectFontLabel"
          id="selectFont"
          value={font}
          label="Font"
          onChange={(e) => setFont(e.target.value)}
        >
          <MenuItem value="AnonymousPro" style={{fontFamily: "AnonymousPro"}}>Anonymous Pro</MenuItem>
          <MenuItem value="Cella" style={{fontFamily: "Cella"}}>Cella</MenuItem>
          <MenuItem value="Monospace" style={{fontFamily: "Monospace"}}>Monospace</MenuItem>
          <MenuItem value="SansMonoRoman" style={{fontFamily: "SansMonoRoman"}}>Sans Mono Roman</MenuItem>
        </Select>
    </FormControl>
      <div style={{display: "flex", flexDirection: "row", marginTop: 20, gap: 10}}>
        <TextField
            id="Font Size"
            label="Font Size"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            style={{flex: 1}}
            InputProps={{ inputProps: { min: 1, max: 200 } }}
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
            onBlur={(e) => e.target.value < 1 ? setFontSize(1) : e.target.value > 200 ? setFontSize(200) : undefined}
          />
          <TextField
            id="Line Spacing"
            label="Line Spacing"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            style={{flex: 1}}
            InputProps={{ inputProps: { min: 0, max: 100 } }}
            value={lineSpacing}
            onChange={(e) => setLineSpacing(e.target.value)}
            onBlur={(e) => e.target.value < 0 ? setLineSpacing(0) : e.target.value > 100 ? setLineSpacing(100) : undefined}
          />
      </div>

      <div style={{display: "flex", flexDirection: "row", marginTop: 10, gap: 10}}>
        <TextField
            id="Image Scaling"
            label="Image Scaling"
            type="number"
            style={{flex: 1}}
            InputProps={{
              inputProps: { min: 1, max: 1000 },
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            value={imageScaling}
            onChange={(e) => setImageScaling(e.target.value)}
            onBlur={(e) => e.target.value < 1 ? setImageScaling(1) : e.target.value > 1000 ? setImageScaling(1000) : undefined}
          />
      </div>
      <div style={{marginTop: 20}}>
        <FormControlLabel control={<></>} label="Text Colour" style={{marginLeft: 5}} />
        <ColourPicker colour={textColour} setColour={setTextColour} />
      </div>
      
      <Divider style={{marginTop: "5px", width: "100%"}} />

      <div style={{display: "flex", flexDirection: "row", width: "100%", marginTop: "20px", marginBottom: "20px", gap: 20}}>
        <BtnWrapper>
          <FullButton
            title="Load Preview"
            action={() => {
              drawCanvas(
                "canvas",
                logo,
                codeText,
                canvasWidth,
                canvasHeight,
                backgroundColour,
                fontSize,
                font,
                textColour,
                lineSpacing,
                imageScaling,
                setLogoError,
                setLoadedPoster,
                setCodeError,
                setLoadingPoster,
                true
              );
              /*PreviewPoster(
              logo,
              codeText,
              canvasWidth,
              canvasHeight,
              backgroundColour,
              fontSize,
              lineSpacing,
              imageScaling,
              textColour,
              font,
              setLogoError,
              setLoadedPoster,
              setCodeError,
              setLoadingPoster)*/}}
            loading={loadingPoster}
            className="umami--click--load-preview-pressed" />
        </BtnWrapper>
        <BtnWrapper>
          <FullButton
          title="Download Without Watermark (£5)"
          action={() => {
            drawCanvas(
              "canvas",
              logo,
              codeText,
              canvasWidth,
              canvasHeight,
              backgroundColour,
              fontSize,
              font,
              textColour,
              lineSpacing,
              imageScaling,
              setLogoError,
              setLoadedPoster,
              setCodeError,
              setLoadingPoster,
              true
            );
            drawCanvas(
              "canvasBackup",
              logo,
              codeText,
              canvasWidth,
              canvasHeight,
              backgroundColour,
              fontSize,
              font,
              textColour,
              lineSpacing,
              imageScaling,
              setLogoError,
              setLoadedPoster,
              setCodeError,
              setLoadingPoster,
              false
            );
            const data = ProcessFormData(logo, codeText, canvasWidth, canvasHeight, backgroundColour, fontSize, lineSpacing, imageScaling, textColour, font, setLogoError, setLoadedPoster, setCodeError, setLoadingPoster);

            setLoadingPoster(false);
            if (data === undefined){
              return;
            }

            /*PreviewPoster(
              logo,
              codeText,
              canvasWidth,
              canvasHeight,
              backgroundColour,
              fontSize,
              lineSpacing,
              imageScaling,
              textColour,
              font,
              setLogoError,
              setLoadedPoster,
              setCodeError,
              setLoadingPoster);*/
            setOpenPurchaseModal(true);
          }}
          loading={loadingPoster} />
        </BtnWrapper>
      </div>
    </div>
  );
}

const BtnWrapper = styled.div`
  flex: 1;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
