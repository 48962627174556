import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Typography, InputLabel, FormControlLabel, Checkbox, TextField } from '@mui/material';


const dropText = 'Drop the file here';

const FileUpload = ({setFile, dragAndDropText, title, multiple, accept, showFileUpload, codeText, setCodeText, error}) => {
  const [text, setText] = useState(dragAndDropText);
  const [dragOver, setDragOver] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [uploadFile, setUploadFile] = useState(true);

  const onDrop = useCallback((acceptedFiles) => {
    if (multiple){
      setText(acceptedFiles.length + " Files");
      setFile(acceptedFiles);
      setCodeText("");
      acceptedFiles.forEach((file) => {
        var reader = new FileReader();
        reader.onload = function(e) {
          var contents = e.target.result;
          setCodeText(x => x + contents);
        };
        reader.readAsText(file);
      });
    }
    else{
      acceptedFiles.forEach((file) => {
        setText(file.name);
        setFile(file);
      });
    }
    
  }, [setFile, multiple, setCodeText]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: multiple,
    accept: accept,
    onDragOver: () => {
      setDragOver(true);
      setText(dropText);
    },
    onDragLeave: () => {
      setDragOver(false);
      setText(dragAndDropText);
    },
  });

  return (
    <div style={{ display: "flex", justifyContent: "center", flex: 1, flexDirection: "column", margin: 0, padding: 0, minHeight: "100px" }}>
      <div style={{display: "flex", flexDirection: "row", gap: 10, marginBottom: showFileUpload ? undefined : 10}}>
        <InputLabel style={{paddingTop: 9, color: "black"}}>{title}</InputLabel>
        {showFileUpload && <FormControlLabel control={<Checkbox defaultChecked value={uploadFile} onChange={(_, checked) => setUploadFile(checked)} />} label="Upload File" />}
      </div>
      {uploadFile ? 
      <div {...getRootProps()} style={{height: "100%"}}>
        <input {...getInputProps()} />
        <div
          style={{
            cursor: 'pointer',
            width: '100%',
            height: "100%",
            borderRadius: 10,
            border: (dragOver || hovering || error ? '2' : '1') + 'px dashed ' + (error ? "red" : "gray"),
            verticalAlign: 'center',
            textAlign: 'center',
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
          }}
          onMouseOver={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}>
          <FileUploadIcon />
          <Typography>{text}</Typography>
        </div>
      </div>
      :
      <TextField
          id="textToUse"
          label="Code Text To Use"
          multiline
          value={codeText}
          onChange={(e) => {setCodeText(e.target.value); setFile(undefined)}}
          maxRows={5}
          error={error}
        />}
    </div>
  );
};

export default FileUpload;
