import React from "react";
import {isMobile} from 'react-device-detect';
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Features from "../components/Sections/Features";
import GetInspired from "../components/Sections/GetInspired";
import Pricing from "../components/Sections/Pricing";
import Footer from "../components/Sections/Footer"

export default function Landing() {
  return (
    <div style={{position: "relative", minHeight: '100vh'}}>
      <div style={{paddingBottom: isMobile ? "7rem" : '2.5rem'}}>
        <TopNavbar showNavigationElements/>
        <Header />
        <Features />
        <GetInspired />
        {/*<Blog />*/}
        <Pricing />
        {/*<Contact />*/}
        </div>
      <Footer showBackToTop/>
    </div>
  );
}


