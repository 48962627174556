import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress} from '@mui/material';
import {isMobile} from "react-device-detect";

const PurchaseModal = ({open, handleClose, handlePayment, loadedPoster, loadingPoster}) => {
    const [purchaseLoading, setPurchaseLoading] = useState(false);
    return (
        <Dialog
            open={open}
            onClose={() => !purchaseLoading && handleClose()}
            fullScreen={isMobile}
        >
            <DialogTitle className="extraBold font60" style={{fontSize: 25, paddingBottom: 0, lineHeight: 2}}>
                Purchase Poster
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="font60" style={{fontSize: 18, }}>
                    Please press the Purchase Poster button below to be able to download the current version of your poster without a watermark
                    <br/>
                    <br/>
                    After purchasing your poster, you will be redirected to a link for download
                    <br/>
                    <br/>
                    Please keep your poster safe, as your link will expire after 24 hours
                    {purchaseLoading &&
                    <>
                        <br/>
                        <br/>
                        Please wait while we load the payment window
                    </>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button
                style={{color: "#fba52d"}}
                disabled={purchaseLoading}
                onClick={handleClose}>Cancel</Button>
            <Button 
                style={{color: "#fba52d"}}
                disabled={purchaseLoading}
                onClick={() => {
                    if (!purchaseLoading){
                        setPurchaseLoading(true);
                        handlePayment();
                    }
                    }}
                autoFocus>
                {purchaseLoading ? <CircularProgress style={{color: "#fba52d", margin: 0}} size="25px"/> : "Purchase Poster"}
            </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PurchaseModal;
