import React, { useState } from "react";
import {TextField, FormControl, InputLabel, Select, MenuItem} from '@mui/material';

export default function Editor({canvasWidth, setCanvasWidth, canvasHeight, setCanvasHeight}) {
  const [canvasSizePreset, setCanvasSizePreset] = useState(CanvasSize.A3Portrait);

  return (
   <>
        <FormControl fullWidth>
            <InputLabel id="canvasSizeLabel">Canvas Size</InputLabel>
            <Select
            labelId="canvasSizeLabel"
            id="canvasSize"
            value={canvasSizePreset}
            label="Canvas Size"
            onChange={(e) => {
                setCanvasSizePreset(e.target.value);

                if (CanvasSizeConversion[e.target.value][0] !== 0){
                    setCanvasWidth(CanvasSizeConversion[e.target.value][0]);
                }
                if (CanvasSizeConversion[e.target.value][1] !== 0){
                    setCanvasHeight(CanvasSizeConversion[e.target.value][1]);
                }
            }}
            >
                <MenuItem value={CanvasSize.A1Portrait}>A1 Portrait</MenuItem>
                <MenuItem value={CanvasSize.A2Portrait}>A2 Portrait</MenuItem>
                <MenuItem value={CanvasSize.A3Portrait}>A3 Portrait</MenuItem>
                <MenuItem value={CanvasSize.A4Portrait}>A4 Portrait</MenuItem>
                <MenuItem value={CanvasSize.A5Portrait}>A5 Portrait</MenuItem>
                <MenuItem value={CanvasSize.A1Landscape}>A1 Landscape</MenuItem>
                <MenuItem value={CanvasSize.A2Landscape}>A2 Landscape</MenuItem>
                <MenuItem value={CanvasSize.A3Landscape}>A3 Landscape</MenuItem>
                <MenuItem value={CanvasSize.A4Landscape}>A4 Landscape</MenuItem>
                <MenuItem value={CanvasSize.A5Landscape}>A5 Landscape</MenuItem>
                <MenuItem value={CanvasSize.InstagramStory}>Instagram - Story</MenuItem>
                <MenuItem value={CanvasSize.InstagramPost}>Instagram - Post</MenuItem>
                <MenuItem value={CanvasSize.FacebookCover}>Facebook - Cover</MenuItem>
                <MenuItem value={CanvasSize.FacebookStory}>Facebook - Story</MenuItem>
                <MenuItem value={CanvasSize.FacebookPost}>Facebook - Post</MenuItem>
                <MenuItem value={CanvasSize.TwitterCover}>Twitter - Cover</MenuItem>
                <MenuItem value={CanvasSize.Tweet}>Twitter - Tweet</MenuItem>
                <MenuItem value={CanvasSize.ImageSize}>Image Size</MenuItem>
                <MenuItem value={CanvasSize.Custom}>Custom</MenuItem>
            </Select>
        </FormControl>
        {canvasSizePreset === CanvasSize.Custom && (
        <div style={{display: "flex", flexDirection: "row", marginTop: 20, gap: 10}}>
            <TextField
                id="Canvas Width"
                label="Canvas Width"
                type="number"
                
                InputLabelProps={{
                shrink: true,
                }}
                style={{flex: 1}}
                InputProps={{ inputProps: { min: 100, max: 99999 } }}
                value={canvasWidth || 0}
                onChange={(e) => setCanvasWidth(e.target.value)}
                onBlur={(e) => e.target.value < 100 ? setCanvasWidth(100) : e.target.value > 9999 ? setCanvasWidth(9999) : undefined}
            />
            <TextField
                id="Canvas Height"
                label="Canvas Height"
                type="number"
                InputLabelProps={{
                shrink: true,
                }}
                style={{flex: 1}}
                InputProps={{ inputProps: { min: 100, max: 99999 } }}
                value={canvasHeight || 0}
                onChange={(e) => setCanvasHeight(e.target.value)}
                onBlur={(e) => e.target.value < 100 ? setCanvasHeight(100) : e.target.value > 9999 ? setCanvasHeight(9999) : undefined}
            />
        </div>)}
    </>
  );
}

// Canvas size (width, height)
const CanvasSize = {
    A1Portrait: "A1Portrait",
    A2Portrait: "A2Portrait",
    A3Portrait: "A3Portrait",
    A4Portrait: "A4Portrait",
    A5Portrait: "A5Portrait",
    A1Landscape: "A1Landscape",
    A2Landscape: "A2Landscape",
    A3Landscape: "A3Landscape",
    A4Landscape: "A4Landscape",
    A5Landscape: "A5Landscape",
    InstagramPost: "InstagramPost",
    InstagramStory: "InstagramStory",
    FacebookPost: "FacebookPost",
    FacebookStory: "FacebookStory",
    FacebookCover: "FacebookCover",
    Tweet: "Tweet",
    TwitterCover: "TwitterCover",
    ImageSize: "ImageSize",
    Custom: "Custom",
}

const CanvasSizeConversion = {
    A1Portrait: [7016, 9933],
    A2Portrait: [4961, 7016],
    A3Portrait: [3508, 4961],
    A4Portrait: [2480, 3508],
    A5Portrait: [1748, 2480],
    A1Landscape: [9933, 7016],
    A2Landscape: [7016, 4961],
    A3Landscape: [4961, 3508],
    A4Landscape: [3508, 2480],
    A5Landscape: [2480, 1748],
    InstagramPost: [1080, 1080],
    InstagramStory: [1080,1920],
    FacebookPost: [1200, 630],
    FacebookStory: [1080, 1920],
    FacebookCover: [820, 312 ],
    Tweet: [1200, 675],
    TwitterCover: [500, 1500],
    ImageSize: [undefined, undefined],
    Custom: [0, 0],
}