import * as React from "react";
import Poster from "./poster.svg"

function SvgComponent(props) {
  return (
    <img
      src={Poster}
      alt="Poster Icon"
      {...props}
    />
  );
}

export default SvgComponent;
