import React from "react";
// Sections
import TopNavbar from "../../components/Nav/TopNavbar";
import Footer from "../../components/Sections/Footer";
import styled from "styled-components";

export default function GenericLegalScreen({component}) {
  return (
    <div style={{position: "relative", minHeight: '100vh'}}>
      <div style={{paddingBottom: '5rem'}}>
        <TopNavbar/>
        <Wrapper id="home" className="container">
          {component}
        </Wrapper>
      </div>
      <Footer />
    </div>
  );
}

const Wrapper = styled.section`
  text-align: left;
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  flex-direction: column;
  @media (max-width: 960px) {
    flex-direction: column;
  }

  h1,h2{
    margin-top: 40px;
  }
`;

