import React from "react";
import {isMobile} from 'react-device-detect';
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import PurchasePage from "../components/Sections/PurchasePage";
import Footer from "../components/Sections/Footer";

export default function Purchase() {
  return (
    <div style={{position: "relative", minHeight: '100vh'}}>
      <div style={{paddingBottom: isMobile ? "3.3rem" : '1rem'}}>
        <TopNavbar/>
        <PurchasePage />
      </div>
      <Footer />
    </div>
  );
}


