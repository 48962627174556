import * as React from "react";
import Roller from "./roller.svg"

function SvgComponent(props) {
  return (
    <img
      src={Roller}
      alt="An icon of a paint roller to show how customisable Code Poster is"
      {...props}
    />
  );
}

export default SvgComponent;
