import NoDataPoster from "../../assets/img/ErrorNoCode.png";

const ProcessFormData = (
    logo,
    codeText,
    canvasWidth,
    canvasHeight,
    backgroundColour,
    fontSize,
    lineSpacing,
    imageScaling,
    textColour,
    font,
    setLogoError,
    setLoadedPoster,
    setCodeError,
    setLoadingPoster
) => {
    if (logo === undefined){
        setLogoError(true);
        setLoadedPoster(NoDataPoster);
        return;
      }
      setLogoError(false);
      if (codeText === undefined || codeText === null || codeText.replace(/\s/g, "").length === 0){
        setCodeError(true);
        setLoadedPoster(NoDataPoster);
        return;
      }
      setCodeError(false);

      setLoadingPoster(true);
      var data = new FormData()
      data.append('logo', logo);
      data.append('codeText', codeText.replace(/\s/g, ""));
      data.append('canvasWidth', canvasWidth);
      data.append('canvasHeight', canvasHeight);
      data.append('backgroundColour', backgroundColour);
      data.append('fontSize', fontSize);
      data.append('lineSpacing', lineSpacing);
      data.append('imageScaling', imageScaling);
      data.append('textColour', textColour);
      data.append('font', font);

      return data;
}

export default ProcessFormData;